import React, { useState, useEffect, useRef } from "react";
import { Layout, Tabs } from "antd";
import HeaderComponent from "../components/HeaderKDS";
import FooterComponent from "../components/Footer";
import OrderCard from "../components/OrderCard";
import { getApitoken, getKitchenkds } from "../module/kichtenCDS/kitchencds";
import GoogleFontLoader from "react-google-font-loader";
import io from "socket.io-client";
import "../assets/css/styles.css";

const { Content } = Layout;
const { TabPane } = Tabs;

function OrderManagement() {
  const [openOrders, setOpenOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [apiToken, setApiToken] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [finishClickedStates, setFinishClickedStates] = useState({});
  const socket = useRef();

  const costCenter = localStorage.getItem("kdscdsCostCenter");

  const isValidOrder = (order) => {
    return (
      order &&
      typeof order.order_id === "string" &&
      Array.isArray(order.items) &&
      order.cost_center === costCenter
    );
  };

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        setApiToken(res.data.message.data.api_token);
        fetchOrders(res.data.message.data.api_token);
      } else {
        console.error("Error in getting the API Token", res);
      }
    } catch (error) {
      console.error("Error fetching API token:", error);
    }
  };

  const fetchOrders = async (token) => {
    try {
      const openResponse = await getKitchenkds("Open", token);
      if (openResponse.status === 200) {
        setOpenOrders(openResponse.data.message.filter(isValidOrder));
      } else {
        console.error("Failed to fetch open orders:", openResponse);
      }

      const completedResponse = await getKitchenkds("Completed", token);
      if (completedResponse.status === 200) {
        setCompletedOrders(completedResponse.data.message.filter(isValidOrder));
      } else {
        console.error("Failed to fetch completed orders:", completedResponse);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleIncomingData = (data) => {
    const orders = data.data || [];
    const newOpenOrders = [];
    const newCompletedOrders = [];

    orders.forEach((order) => {
      if (isValidOrder(order)) {
        if (order.status === "Open") {
          newOpenOrders.push(order);
        } else {
          newCompletedOrders.push(order);
        }
      } else {
        console.error("Invalid order:", order);
      }
    });

    // Update open orders
    setOpenOrders((prevOpenOrders) => {
      const updatedOpenOrders = [...prevOpenOrders];
      newOpenOrders.forEach((newOrder) => {
        const existingOrderIndex = updatedOpenOrders.findIndex(
          (order) => order.order_id === newOrder.order_id
        );
        if (existingOrderIndex !== -1) {
          updatedOpenOrders[existingOrderIndex] = newOrder;
        } else {
          updatedOpenOrders.push(newOrder);
        }
      });
      return updatedOpenOrders.filter(
        (order) =>
          !newCompletedOrders.some(
            (completedOrder) => completedOrder.order_id === order.order_id
          )
      );
    });

    setCompletedOrders((prevCompletedOrders) => {
      const updatedCompletedOrders = [...prevCompletedOrders];
      newCompletedOrders.forEach((newOrder) => {
        const existingOrderIndex = updatedCompletedOrders.findIndex(
          (order) => order.order_id === newOrder.order_id
        );
        if (existingOrderIndex !== -1) {
          updatedCompletedOrders[existingOrderIndex] = newOrder;
        } else {
          updatedCompletedOrders.push(newOrder);
        }
      });
      return updatedCompletedOrders;
    });
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  useEffect(() => {
    socket.current = io("https://kleonode.nestorhawk.com");

    socket.current.on("connect", () => {
      console.log("Connected to server");
    });

    socket.current.on("message", handleIncomingData);

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  const handleCompleteOrder = async (orderId) => {
    const completedOrder = openOrders.find((order) => order.order_id === orderId);
    if (completedOrder) {
      setCompletedOrders((prevCompletedOrders) => {
        // Check if the order is already in the completedOrders to avoid duplicates
        if (prevCompletedOrders.some((order) => order.order_id === completedOrder.order_id)) {
          return prevCompletedOrders;
        }
        return [...prevCompletedOrders, completedOrder];
      });

      setOpenOrders((prevOpenOrders) => prevOpenOrders.filter((order) => order.order_id !== orderId));
      setFinishClickedStates((prevStates) => {
        const newState = { ...prevStates };
        delete newState[orderId];
        return newState;
      });

      // Re-fetch orders to ensure state consistency
      await fetchOrders(apiToken);
    } else {
      console.error("Order not found in open orders:", orderId);
    }
  };

  const handleFinishClicked = (orderId) => {
    setFinishClickedStates((prevStates) => ({
      ...prevStates,
      [orderId]: !prevStates[orderId],
    }));
  };

  return (
    <Layout>
      <HeaderComponent welcomeMessage="Good Morning," colorFont="Team" />
      <GoogleFontLoader
        fonts={[
          { font: "ABeeZee", weights: [400, 700] },
          { font: "Nunito", weights: [400, 700], display: "swap" },
          { font: "Poppins", weights: [400, 700], display: "swap" },
        ]}
      />

      <Content>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          <TabPane tab="Open" key="1">
            <div className="orders-container">
              {openOrders.map((order) => (
                <div className="order-block" key={order.order_id}>
                  <OrderCard
                    order={order}
                    onComplete={() => handleCompleteOrder(order.order_id)}
                    heading={order.type || ""}
                    items={order.items}
                    apiToken={apiToken}
                    finishClicked={!!finishClickedStates[order.order_id]}
                    onFinishClicked={() => handleFinishClicked(order.order_id)}
                  />
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tab="Completed" key="2">
            <div className="orders-container">
              {completedOrders.map((order) => (
                <div className="order-block" key={order.order_id}>
                  <OrderCard
                    order={order}
                    heading={order.type || ""}
                    items={order.items}
                    apiToken={apiToken}
                    finishClicked={false}
                  />
                </div>
              ))}
            </div>
          </TabPane>
        </Tabs>
      </Content>
      {/* <FooterComponent /> */}
    </Layout>
  );
}

export default OrderManagement;
