import React, { useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import webLogo from "../assets/images/webLogo.svg";
import RestaurantBanner from "../assets/images/RestaurantBanner.png";
import CartIcon from "../assets/images/CartIcon.png";
import ItemCard from "../components/ItemCards";
import {
  fetchStoreDetails,
  getApitoken,
  getItemsGroup,
} from "../module/landingScreen";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import HeaderInnerComp from "../components/Header";
import { ThemeContext } from "../components/ThemeContext";
// import AttributePopup from "../components/AttributePopup";
import ComboItemsPopup from "../components/ComboItemsPopup";
import AttributesPopup from "../components/AttributesPopup";

const { Header } = Layout;

function RestaurantDetails() {
  const {
    cost_center_name,
    custom_address,
    custom_location,
    web_outlet_details_banner_image,
  } = useParams();
  const location = useLocation();
  const outlet = location.state?.outlet;

  const { id } = useParams();
  localStorage.setItem("costCenter", id);

  const { theme } = useContext(ThemeContext);

  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const [apiToken, setApiToken] = useState([]);
  const [items, setItems] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [selectedItemGroup, setSelectedItemGroup] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const [storeDetails, setStoreDetails] = useState([]);

  const [banner, setBanner] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAttributesPopupVisible, setIsAttributesPopupVisible] =
    useState(false);
  const [isComboItemsPopupVisible, setIsComboItemsPopupVisible] =
    useState(false);

  // Function to calculate tax for an item
  const calculateItemTax = (item, price) => {
    const mainItemTaxPercentage = item.tax?.[0]?.custom_tax_percentage
      ? parseFloat(item.tax[0].custom_tax_percentage)
      : 0;
    const itemTaxPercentage = item.item_tax?.[0]?.custom_tax_percentage
      ? parseFloat(item.item_tax[0].custom_tax_percentage)
      : 0;
    const effectiveTaxPercentage = Math.max(
      mainItemTaxPercentage,
      itemTaxPercentage
    );

    return (price * effectiveTaxPercentage) / 100;
  };

  // Calculate subtotal and total tax including related and subrelated items
  const calculateSubtotalAndTax = () => {
    let subtotal = 0;
    let totalTax = 0;

    cartItems.forEach((item) => {
      let itemTotalPrice = item.product_price * item.quantity;
      let itemTax = calculateItemTax(item, item.product_price) * item.quantity;

      const selectedAttributes = item.selectedAttributes;
      const selectedComboItems = item.selectedComboItems;

      if (selectedAttributes) {
        Object.values(selectedAttributes).forEach((attributeItemArray) => {
          if (Array.isArray(attributeItemArray)) {
            attributeItemArray
              .filter((option) => option !== null)
              .forEach((option) => {
                if (option.price) {
                  itemTotalPrice += parseFloat(option.price) * item.quantity;
                  itemTax +=
                    calculateItemTax(option, parseFloat(option.price)) *
                    item.quantity;
                }
              });
          }
        });
      }

      if (selectedComboItems) {
        Object.values(selectedComboItems).forEach((comboItemArray) => {
          if (Array.isArray(comboItemArray)) {
            comboItemArray
              .filter((comboItem) => comboItem !== null)
              .forEach((comboItem) => {
                if (comboItem.price) {
                  itemTotalPrice += parseFloat(comboItem.price) * item.quantity;
                  itemTax +=
                    calculateItemTax(comboItem, parseFloat(comboItem.price)) *
                    item.quantity;
                }
              });
          }
        });
      }
      subtotal += itemTotalPrice;
      totalTax += itemTax;
    });

    return { subtotal, totalTax };
  };

  const { subtotal, totalTax } = calculateSubtotalAndTax();
  const totalWithTax = subtotal + totalTax;

  const showPopup = (itemDetails) => {
    setSelectedItemDetails(itemDetails);
    setIsPopupVisible(true);
  };

  const handleShowAttributesPopup = async (item) => {
    setSelectedItem(item);
    setIsAttributesPopupVisible(true);
  };

  const handleShowComboItemsPopup = async (item) => {
    setSelectedItem(item);
    setIsComboItemsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setSelectedItemDetails(null);
  };

  const handleDecreaseQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity--;
    if (updatedCartItems[index].quantity <= 0) {
      updatedCartItems.splice(index, 1);
    }
    setCartItems(updatedCartItems);
  };

  const handleIncreaseQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
  };

  const storeHeadingDetailsInLocalStorage = () => {
    const costCenterDetails = {
      cost_center_name: outlet ? outlet.cost_center_name : cost_center_name,
      custom_address: outlet ? outlet.custom_address : custom_address,
      custom_location: outlet ? outlet.custom_location : custom_location,
      web_outlet_details_banner_image: outlet
        ? outlet.web_outlet_details_banner_image
        : web_outlet_details_banner_image,
    };
    localStorage.setItem("headingDetails", JSON.stringify(costCenterDetails));
  };

  // Call this function where appropriate, like after fetching data
  storeHeadingDetailsInLocalStorage();

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data.api_token;
        setApiToken(apiToken);
        setBanner(res.data.message.data);
        const response = await getItemsGroup(apiToken, id);
        if (response.status === 200) {
          const validGroups = response.data.message.filter(
            (group) => group.items && group.items.length > 0
          );
          const groups = validGroups.map((group) => group.item_group);
          setItems(response.data.message);
          setItemGroups(groups);
          setSelectedItemGroup(groups[0]);
        } else {
          console.error("Failed to fetch item groups:", response.status);
        }
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAPIToken();
  }, []);

  useEffect(() => {
    const fetchDetailsOnPageLoad = async () => {
      try {
        const headingDetails = JSON.parse(
          localStorage.getItem("headingDetails")
        );
        if (!headingDetails) {
          console.error("Heading details not found in localStorage.");
          return;
        }

        const { custom_location } = headingDetails;
        const response = await fetchStoreDetails(custom_location);

        // Assuming the response is an array of objects as provided earlier
        if (Array.isArray(response) && response.length > 0) {
          const costCenterName = localStorage.getItem("costCenter");
          const details = response.find((item) => item.name === costCenterName);
          if (!details) {
            console.error(
              `Details not found for cost center: ${costCenterName}`
            );
            return;
          }

          setStoreDetails(details);
          // console.log("Details fetched:", details);
        } else {
          console.error("Invalid response format or empty response.");
        }
      } catch (error) {
        console.error("Error fetching store details:", error);
      }
    };

    fetchDetailsOnPageLoad();
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedItemGroup) {
      localStorage.setItem("selectedCategory", selectedItemGroup);
    }
  }, [selectedItemGroup]);

  const addToCart = (itemToAdd) => {
    const { id, selectedComboItems, selectedAttributes } = itemToAdd;

    const localStorageKey = `cartItem_${id}_${JSON.stringify(
      selectedComboItems
    )}_${JSON.stringify(selectedAttributes)}`;

    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const existingItemIndex = storedCartItems.findIndex((cartItem) => {
      return (
        cartItem.id === id &&
        JSON.stringify(cartItem.selectedComboItems) ===
          JSON.stringify(selectedComboItems) &&
        JSON.stringify(cartItem.selectedAttributes) ===
          JSON.stringify(selectedAttributes)
      );
    });

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...storedCartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    } else {
      const newItemToAdd = {
        ...itemToAdd,
        quantity: 1,
        localStorageKey,
      };

      storedCartItems.push(newItemToAdd);

      localStorage.setItem(localStorageKey, JSON.stringify(newItemToAdd));
      localStorage.setItem("cartItems", JSON.stringify(storedCartItems));
    }

    setCartItems(storedCartItems);
  };

  const increaseQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity += 1;
    setCartItems(updatedCartItems);
  };

  const decreaseQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity -= 1;
    } else {
      updatedCartItems.splice(index, 1); // Remove the item if quantity is 0
    }
    setCartItems(updatedCartItems);
  };

  // const removeFromCart = (index) => {
  //   const updatedCartItems = [...cartItems];
  //   updatedCartItems.splice(index, 1);
  //   setCartItems(updatedCartItems);
  // };

  const removeFromCart = (itemToRemove) => {
    const updatedCartItems = cartItems.filter((item) => {
      // Compare ID first
      if (item.id !== itemToRemove.id) {
        return true;
      }
  
      // Compare selectedAttributes (assuming it's an array or object)
      const attributesMatch = JSON.stringify(item.selectedAttributes) === JSON.stringify(itemToRemove.selectedAttributes);
  
      // Compare selectedComboItems (assuming it's an array or object)
      const comboItemsMatch = JSON.stringify(item.selectedComboItems) === JSON.stringify(itemToRemove.selectedComboItems);
  
      // Only keep the item if either the attributes or combo items do not match
      return !(attributesMatch && comboItemsMatch);
    });
  
    setCartItems(updatedCartItems);
  };
  

  const renderItems = () => {
    const selectedGroup = items.find(
      (group) => group.item_group === selectedItemGroup
    );
    if (selectedGroup) {
      return selectedGroup.items.map((item, index) => (
        <ItemCard
          key={index}
          item={item}
          addToCart={() => addToCart(item)}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          cartItems={cartItems}
          itemLink={`/restaurantdetails/${id}/${item.id}`}
          itemState={{
            custom_location: outlet ? outlet.custom_location : custom_location,
          }}
          theme={theme}
          onShowPopup={() => showPopup(item)}
          onShowAttributesPopup={handleShowAttributesPopup}
          onShowComboItemsPopup={handleShowComboItemsPopup}
        />
      ));
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (isCartOpen) {
      document.body.classList.add("no-overflow");
    } else {
      document.body.classList.remove("no-overflow");
    }

    return () => {
      document.body.classList.remove("no-overflow");
    };
  }, [isCartOpen]);

  const totalPrice = cartItems
    .reduce((total, item) => {
      const price = parseFloat(item.product_price);
      const quantity = parseInt(item.quantity, 10);
      return total + (isNaN(price) || isNaN(quantity) ? 0 : price * quantity);
    }, 0)
    .toFixed(2);

  return (
    <>
      <HeaderInnerComp />
      <div className="restaurantDetail-page">
        <div className="container-fluid">
          <section className="rest-banner">
            <img
              src={
                storeDetails.web_outlet_details_banner_image || RestaurantBanner
              }
              className="w-100 section-banner"
              alt="Banner"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = RestaurantBanner;
              }}
            />
          </section>
          <div className="heading-details">
            <h2>{outlet ? outlet.cost_center_name : cost_center_name}</h2>
            <p>{outlet ? outlet.custom_address : custom_address}</p>
            <p className="restaurant-location">
              {outlet ? outlet.custom_location : custom_location}
            </p>
          </div>
          <div className="row" id="Restaurant-id">
            {isMobile ? (
              <div className="col-12" id="Menu_ios">
                <div className="dropdown-wrap">
                  <button
                    className="dropdown-toggle list-group-item list-group-item-action borderless"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {selectedItemGroup || "Select a category"}
                  </button>
                  {dropdownOpen && (
                    <ul className="dropdown-menu show">
                      {itemGroups.map((group, index) => (
                        <li
                          key={index}
                          className={`dropdown-item ${
                            selectedItemGroup === group ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedItemGroup(group);
                            setDropdownOpen(false);
                          }}
                        >
                          {group}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div className="col-md-3">
                <div
                  className="list-group restaurant-menu"
                  id="list-tab"
                  role="tablist"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <div
                    className="scrollable-content"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    {itemGroups
                      .slice(0, itemGroups.length)
                      .map((group, index) => (
                        <li
                          key={index}
                          type="button"
                          className={`list-group-item list-group-item-action borderless ${
                            selectedItemGroup === group ? "active" : ""
                          }`}
                          onClick={() => setSelectedItemGroup(group)}
                        >
                          {group}
                        </li>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-6 col-lg-5 col-md-12">{renderItems()}</div>
            {isMobile ? (
              <button
                className="cart-button"
                onClick={() => setIsCartOpen(true)}
              >
                <img src={CartIcon} alt="Cart" />
                {cartItems.length > 0 && (
                  <span className="cart-item-count">{cartItems.length}</span>
                )}
                <span className="total-price"> £{totalWithTax.toFixed(2)}</span>
              </button>
            ) : (
              <div className="col-xl-3 col-lg-4 col-md-3">
                <Cart
                  cartItems={cartItems}
                  increaseQuantity={increaseQuantity}
                  decreaseQuantity={decreaseQuantity}
                  removeFromCart={removeFromCart}
                  totalPrice={totalPrice}
                  isMobile={isMobile}
                  subtotal={subtotal}
                  totalTax={totalTax}
                  totalWithTax={totalWithTax}
                  updateTotalWithTax={totalWithTax}
                  theme={theme}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && isCartOpen && (
        <Cart
          cartItems={cartItems}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          removeFromCart={removeFromCart}
          totalPrice={totalPrice}
          subtotal={subtotal}
          totalTax={totalTax}
          totalWithTax={totalWithTax}
          updateTotalWithTax={totalWithTax}
          setIsCartOpen={setIsCartOpen}
          isMobile={isMobile}
          theme={theme}
        />
      )}
      {isAttributesPopupVisible && (
        <AttributesPopup
          visible={isAttributesPopupVisible}
          item={selectedItem}
          onClose={() => setIsAttributesPopupVisible(false)}
          addToCart={addToCart}
          cartItems={cartItems}
          // decreaseCartItemQuantity={decreaseQuantity}
          // increaseCartItemQuantity={increaseQuantity}
          decreaseQuantity={handleDecreaseQuantity}
          increaseQuantity={handleIncreaseQuantity}
          removeFromCart={removeFromCart}
        />
      )}

      {isComboItemsPopupVisible && (
        <ComboItemsPopup
          visible={isComboItemsPopupVisible}
          item={selectedItem}
          onClose={() => setIsComboItemsPopupVisible(false)}
          addToCart={addToCart}
          cartItems={cartItems}
          // decreaseCartItemQuantity={decreaseQuantity}
          // increaseCartItemQuantity={increaseQuantity}
          decreaseQuantity={handleDecreaseQuantity}
          increaseQuantity={handleIncreaseQuantity}
          removeFromCart={removeFromCart}
        />
      )}

      <Footer />
    </>
  );
}

export default RestaurantDetails;
