import React, { useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import webLogo from "../assets/images/webLogo.svg";
import { useLocation } from "./LocationContext";
import "../App.css";
import { getApitoken, getLocation } from "../module/landingScreen";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ThemeContext } from "./ThemeContext";

const { Header } = Layout;

const HeaderComponent = () => {
  const { selectedLocation, setSelectedLocation } = useLocation();
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiToken, setApiToken] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleLocationChange = (event, value) => {
    setSelectedLocation(value);
  };

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);
  const { theme } = useContext(ThemeContext);
  const fetchLocations = async (searchLocation = "") => {
    setLoading(true);
    try {
      const res = await getLocation(searchLocation);
      if (res && res.status === 200) {
        const locations =
          res.data.message.map((item) => item.custom_location) || [];
        setLocation(locations);
      } else {
        console.error("Failed to get location:", res.status);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleSearch = (value) => {
    fetchLocations(value);
  };

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <a href="/" className="logo">
          <img src={`${apiToken?.web_logo_image}`} alt="Logo" />
        </a>
        <div className="search-dropdown-wrap">
          <Autocomplete
            className="header-dropdowns"
            style={{ width: 200 }}
            options={location}
            loading={loading}
            onInputChange={(event, value) => handleSearch(value)}
            onChange={handleLocationChange}
            value={selectedLocation || ""}
            open={isOpen}
            onOpen={() => handleDropdownVisibleChange(true)}
            onClose={() => handleDropdownVisibleChange(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select City"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      <InputAdornment position="end">
                        <IconButton onClick={toggleDropdown}>
                          {isOpen ? <SearchOutlined /> : <DownOutlined />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="blank-div">&nbsp;</div>
      </div>
    </header>
  );
};

export default HeaderComponent;
