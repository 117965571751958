import React, { useEffect, useState } from "react";
import RemoveFromCart from "../assets/images/RemoveFromCart.svg";
import CartIcon from "../assets/images/CartIcon.png";
import CustomerDetails from "../components/CustomerDetails";

const Cart = ({
  cartItems,
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
  setIsCartOpen,
  isMobile,
  updateTotalWithTax,
  subtotal,
  totalTax,
  totalWithTax,
}) => {
  const [orderRequest, setOrderRequest] = useState("");
  const [orderRequestError, setOrderRequestError] = useState("");

  useEffect(() => {
    if (cartItems.length === 0) {
      setOrderRequest("");
    }
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("subtotal", (subtotal || 0).toFixed(2));
    localStorage.setItem("totalTax", (totalTax || 0).toFixed(2));
  }, [subtotal, totalTax]);

  useEffect(() => {
    if (typeof updateTotalWithTax === "function") {
      updateTotalWithTax(totalWithTax);
    }
  }, [totalWithTax, updateTotalWithTax]);

  const handleOrderRequestChange = (e) => {
    const value = e.target.value;
    if (value.length <= 150) {
      setOrderRequest(value);
      setOrderRequestError("");
    } else {
      setOrderRequestError("Order request cannot exceed 150 characters.");
    }
  };

  const renderQuantityControls = (index) => {
    const cartItem = cartItems[index];
    return (
      <div className="border quantity-control">
        <span className="" onClick={() => decreaseQuantity(index)}>
          -
        </span>
        <span className="mx-2">{cartItem?.quantity || 0}</span>
        <span className="mr-1" onClick={() => increaseQuantity(index)}>
          +
        </span>
      </div>
    );
  };

  return (
    <div
      className={`cart-container ${isMobile ? "cart-popup" : "cart-sidebar"}`}
    >
      <div className="cart-wrap">
        <div className="d-flex justify-content-between cart-head">
          <h2 className="cart-heading">Basket</h2>
          {cartItems.length > 0 ? (
            <span className="cart-item-count-text">
              {cartItems.length === 1
                ? `${cartItems.length} Item`
                : `${cartItems.length} Items`}
            </span>
          ) : (
            <span className="cart-item-count-text">0 Item</span>
          )}

          {isMobile && (
            <button
              className="btn btn-link text-danger"
              onClick={() => setIsCartOpen(false)}
            >
              Close
            </button>
          )}
        </div>
        <div className="cart-content">
          {cartItems.length === 0 ? (
            <div className="empty-cart">
              <img
                src={CartIcon}
                className="d-flex justify-content-center p-2 mx-auto"
                alt=""
              />
              <p className="d-flex justify-content-center">No Item In Basket</p>
            </div>
          ) : (
            <>
              <div
                style={{
                  maxHeight: cartItems.length > 4 ? "260px" : "auto",
                  overflowY: cartItems.length > 4 ? "scroll" : "auto",
                }}
                className="scrollable-content"
              >
                {cartItems.map((item, index) => {
                  const itemPrice = item.product_price || 0;
                  const selectedAttributes = item.selectedAttributes;
                  const selectedComboItems = item.selectedComboItems;
                  console.log(
                    selectedComboItems,
                    "selected items selected in basket"
                  );
                  let totalItemPrice = itemPrice || 0; // Ensure totalItemPrice is a number

                  if (selectedAttributes) {
                    Object.values(selectedAttributes).forEach(
                      (attributeItemArray) => {
                        if (Array.isArray(attributeItemArray)) {
                          attributeItemArray.forEach((option) => {
                            if (option && option.price) {
                              totalItemPrice += parseFloat(option.price) || 0;
                            }
                          });
                        }
                      }
                    );
                  }

                  if (selectedComboItems) {
                    Object.values(selectedComboItems).forEach(
                      (comboItemArray) => {
                        if (Array.isArray(comboItemArray)) {
                          comboItemArray.forEach((comboItem) => {
                            if (comboItem && comboItem.price) {
                              totalItemPrice +=
                                parseFloat(comboItem.price) || 0;
                            }
                          });
                        }
                      }
                    );
                  }

                  return (
                    <div
                      key={index}
                      className="p-2 border-bottom align-items-center justify-content-between cart-body"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                            {item.name} - <span className="text-black">£</span>
                            {itemPrice.toFixed(2)}
                          </p>
                          {selectedAttributes && (
                            <div className="selected-attribute">
                              {Object.values(selectedAttributes).map(
                                (attributeItemArray, attributeIndex) => (
                                  <div key={attributeIndex} className="cart-ca">
                                    {Array.isArray(attributeItemArray) &&
                                      attributeItemArray.map(
                                        (option, attributeItemIndex) => (
                                          <p key={attributeItemIndex}>
                                            {option.item_name || option.item} -
                                            £{option.price || 0}
                                          </p>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {selectedComboItems && (
                            <div className="selected-attribute">
                              {Object.values(selectedComboItems).map(
                                (comboItemArray, comboIndex) => (
                                  <div key={comboIndex} className="cart-ca">
                                    {Array.isArray(comboItemArray) &&
                                      comboItemArray.map(
                                        (comboItem, comboIndex) => (
                                          <p key={comboIndex}>
                                            {comboItem.item_name ||
                                              comboItem.item}
                                            - £{comboItem.price || 0}
                                          </p>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        <div className="cart-right-controls">
                          <div className="d-flex align-items-center justify-content-between qty-delete">
                            {renderQuantityControls(index)}
                            <img src={RemoveFromCart} alt="" onClick={() => removeFromCart(item)}></img>

                          </div>
                          <div className="d-flex fw-semibold cart-item-price">
                            <span className="text-black">£</span>
                            {totalItemPrice.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="py-3 order-note">
                <h6>Order Request</h6> 
                <input
                  type="text"
                  placeholder="Enter your order request"
                  value={orderRequest}
                  onChange={handleOrderRequestChange}
                  className="form-control p-0 order-note-field"
                  style={{ border: "none", boxShadow: "none" }}
                />
                {orderRequestError && (
                  <small className="text-danger">{orderRequestError}</small>
                )}
              </div>
              <div className="py-3 d-flex flex-column gap-2 border-top">
                <div className="cart-price-row">
                  <p className="fw-semibold text-black">Subtotal</p>
                  <p className="fw-semibold d-flex">
                    <span>£</span>
                    {subtotal.toFixed(2)}
                  </p>
                </div>
                <div className="cart-price-row">
                  <p className="text-black">VAT</p>
                  <p className="d-flex">
                    <span>£</span>
                    {totalTax.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="py-3 cart-total-row">
                <p className="text-black">Total</p>
                <p className="d-flex">
                  <span>£</span>
                  {totalWithTax.toFixed(2)}
                </p>
              </div>
              <div className="checkout-btn-wrap">
                <CustomerDetails
                  cartItems={cartItems}
                  totalPrice={totalWithTax.toFixed(2)}
                  orderRequest={orderRequest}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && (
        <div
          className="cart-popup-overlay"
          onClick={() => setIsCartOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default Cart;
