// HeaderComponent.js
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import webLogo from "../assets/images/webLogo.svg"; 
import "../App.css";
import { getApitoken } from "../module/landingScreen";

const { Header } = Layout;

const HeaderInnerComp = () => {
  const [apiToken, setApiToken] = useState(false);
  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  return (
    <header className="header">
      <div className="container-fluid">
        <a href="/" className="logo">
          <img
            src={`${apiToken?.web_logo_image}`}
            alt="Logo" 
          />
        </a>
      </div>
    </header>
  );
};

export default HeaderInnerComp;
