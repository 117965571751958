// App.js

import React from "react";
import { LocationProvider } from "./components/LocationContext";
import HeaderComponent from "./components/WebHeader";
import WebLanding from "../src/screens/WebLanding";

function App() {
  return (
    <>
    <LocationProvider>
      <HeaderComponent />
      <WebLanding />
    </LocationProvider>
  </>
  );
}

export default App;
