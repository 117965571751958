import React, { useEffect, useState } from "react";
import { Modal, Button, Spin } from "antd";
import NoImgFood from "../assets/images/ItemImage.png"; // Placeholder image for items without image

function ComboItemsPopup({
  visible,
  onClose,
  item,
  addToCart,
  cartItems,
  decreaseCartItemQuantity,
  increaseCartItemQuantity,

  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
}) {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [comboItems, setComboItems] = useState([]);
  const [selectedComboItems, setSelectedComboItems] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const getComboItems = async () => {
      if (visible && item) {
        setLoading(true);
        try {
          const storedComboItems = localStorage.getItem(
            `combo_items_${item.id}`
          );
          const fetchedComboItems = storedComboItems
            ? JSON.parse(storedComboItems)
            : [];
          setComboItems(fetchedComboItems);
        } catch (error) {
          console.error("Error fetching combo items:", error);
          setComboItems([]);
        } finally {
          setLoading(false);
        }
      }
    };

    if (visible) {
      setValidationErrors([]);
      setSelectedComboItems([]);
      getComboItems();
    }
  }, [visible, item]);

  const calculateTotalPrice = () => {
    let totalPrice = parseFloat(item.product_price) || 0;
  
    // Calculate base item price with tax
    totalPrice = parseFloat(calculatePriceWithTax(item.product_price, item.tax));
  
    // Calculate combo items price with tax
    comboItems.forEach((comboItemGroup, groupIndex) => {
      const groupSelections = selectedComboItems[groupIndex] || [];
  
      groupSelections.forEach((selectedOption) => {
        totalPrice += parseFloat(calculatePriceWithTax(selectedOption.price, selectedOption.item_tax)) || 0;
      });
    });
  
    return totalPrice.toFixed(2); // Return the total price rounded to 2 decimal places
  };
 

  const calculatePriceWithTax = (basePrice, taxInfo) => {
    let totalPrice = parseFloat(basePrice) || 0;

    taxInfo.forEach((tax) => {
      const taxPercentage = parseFloat(tax.custom_tax_percentage) || 0;
      const taxAmount = totalPrice * (taxPercentage / 100);
      totalPrice += taxAmount;
    });

    return totalPrice.toFixed(2); // Return the total price rounded to 2 decimal places
  };
  const totalPriceWithTax = calculatePriceWithTax(item.product_price, item.tax);

  const handleAddToCart = (event) => {
    event.stopPropagation();

    if (!validateSelections()) {
      return;
    }

    let itemToAdd = { ...item };
    const totalPrice = calculateTotalPrice();

    // Prepare the item to add to cart with selected options
    itemToAdd = {
      ...itemToAdd,
      total_price: totalPrice,
      selectedComboItems: selectedComboItems,
    };

    localStorage.setItem(
      `selectedComboItems_${item.id}`,
      JSON.stringify(selectedComboItems)
    );
    addToCart(itemToAdd);
    onClose();
  };

  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.length > 40
      ? words.slice(0, 40).join(" ") + "..."
      : description;
  };

  const renderAllergens = () =>
    item.allergens?.[0]?.map((allergen, index) => (
      <img
        key={index}
        src={allergen.icon}
        alt={allergen.allergens}
        title={allergen.allergens}
        style={{ width: "24px", height: "24px", marginRight: "5px" }}
      />
    ));
  const toggleExpanded = () => setExpanded(!expanded);

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const renderQuantityControls = () => (
    <div className="quantity-controls">
      <button onClick={handleDecreaseQuantity}>-</button>
      <span>{quantity}</span>
      <button onClick={handleIncreaseQuantity}>+</button>
    </div>
  );


  // const renderQuantityControls = () => {
  //   const cartItemIndex = cartItems.findIndex((ci) => ci.id === item.id);
  //   const cartItem = cartItems[cartItemIndex];
  //   return (
  //     <div className="quantity-controls">
  //       <button
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           decreaseQuantity(cartItemIndex);
  //         }}
  //       >
  //         -
  //       </button>
  //       <span>{cartItem ? cartItem.quantity : 0}</span>
  //       <button
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           increaseQuantity(cartItemIndex);
  //         }}
  //       >
  //         +
  //       </button>
  //     </div>
  //   );
  // };

  const handleOptionSelect = (groupIndex, option) => {
    const requiredSelections = parseSelectionRequirement(
      comboItems[groupIndex]
    );
    const groupSelections = selectedComboItems[groupIndex] || [];
    const isMandatory = comboItems[groupIndex].mandatory === 1;

    setSelectedComboItems((prevSelectedComboItems) => {
      const newSelectedComboItems = [...prevSelectedComboItems];

      if (!newSelectedComboItems[groupIndex]) {
        newSelectedComboItems[groupIndex] = [];
      }

      const optionExistsIndex = groupSelections.findIndex(
        (selectedOption) => selectedOption.item === option.item
      );

      if (optionExistsIndex !== -1) {
        newSelectedComboItems[groupIndex].splice(optionExistsIndex, 1);
      } else if (!isMandatory || groupSelections.length < requiredSelections) {
        newSelectedComboItems[groupIndex] = [
          ...(newSelectedComboItems[groupIndex] || []),
          option,
        ];
      } else {
        if (isMandatory) {
          newSelectedComboItems[groupIndex] = [
            ...newSelectedComboItems[groupIndex].slice(1),
            option,
          ];
        } else {
          newSelectedComboItems[groupIndex] = [
            ...newSelectedComboItems[groupIndex],
            option,
          ];
        }
      }

      return newSelectedComboItems;
    });

    setValidationErrors((prevErrors) =>
      prevErrors.filter(
        (error) => !error.includes(`from ${comboItems[groupIndex].name}`)
      )
    );
  };

  const parseSelectionRequirement = (comboItem) => {
    if (comboItem.mandatory === 1) {
      if (comboItem.description === "Select 0") {
        return 0;
      } else {
        const match = comboItem.description.match(/Select (\d+)/);
        return match ? parseInt(match[1]) : 0;
      }
    }
    return 0;
  };

  const validateSelections = () => {
    const errors = [];

    comboItems.forEach((comboItemGroup, groupIndex) => {
      const requiredSelections = parseSelectionRequirement(comboItemGroup);
      const groupSelections = selectedComboItems[groupIndex] || [];

      if (
        comboItemGroup.mandatory === 1 &&
        groupSelections.length !== requiredSelections
      ) {
        errors.push(
          `Please select exactly ${requiredSelections} item(s) from ${comboItemGroup.name}`
        );
      }
    });

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const numberToWord = (number) => {
    switch (number) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      // Add more cases as needed for other numbers
      default:
        return `${number}`;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      className="attribute-modal combo-modal"
    >
      <div className="w-100 justify-content-center mb-3">
        <div className="d-flex align-items-stretch card-body">
          <div className="item-card-image">
            <img src={item?.image || NoImgFood} alt="Item" />
          </div>
          {item && (
            <div className="item-card-main">
              <div className="desc-left">
                <div className="card-text">
                  <div>
                    <h4 className="card-title">{item.name}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expanded
                          ? item.description
                          : truncateDescription(item.description),
                      }}
                      className={`item-description ${
                        expanded ? "expanded" : "collapsed"
                      }`}
                    ></p>
                    {item.description.split(" ").length > 20 && (
                      <span onClick={toggleExpanded} className="show-more">
                        {expanded ? "Show less" : "Show more"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="item-card-price">
                  <p>
                    £ <span>{totalPriceWithTax}</span>
                  </p>
                </div>
              </div>
              <div className="desc-bottom">
                <div className="allergens-icons">{renderAllergens()}</div>
                <div className="attr-qty-control">
                  {renderQuantityControls()}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="attribute-list combo-list scrollable-content">
          {loading ? (
            <div className="spin-loader">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {comboItems.map((comboItemGroup, groupIndex) => {
                const requiredSelections =
                  parseSelectionRequirement(comboItemGroup);
                const isValidationRequired = comboItemGroup.mandatory === 1;
                const showAsterisk =
                  isValidationRequired && requiredSelections > 0;
                return (
                  <div key={groupIndex} className="combo-block">
                    <h4>
                      {comboItemGroup.name}
                      {showAsterisk && (
                        <>
                          <span className="asterisk">*</span>
                          {isValidationRequired && requiredSelections > 0 && (
                            <span className="select-text">
                              &nbsp;(
                              <span className="selection-requirement">
                                {` Select Any ${numberToWord(
                                  requiredSelections
                                )}`}
                              </span>
                              )
                            </span>
                          )}
                        </>
                      )}
                    </h4>
                    <div className="attribute-cards combo-cards">
                      {comboItemGroup.options.map((option, optionIndex) => (
                        <div
                          className={`attribute-content ${
                            (selectedComboItems[groupIndex] || []).some(
                              (selectedOption) =>
                                selectedOption.item === option.item
                            )
                              ? "selected"
                              : ""
                          }`}
                          key={optionIndex}
                          onClick={() => handleOptionSelect(groupIndex, option)}
                        >
                          <div className="combo-img">
                            <img src={option.item_image} alt="" />
                          </div>
                          <div className="combo-desc">
                            <span className="combo-name">
                              {option.item_name}
                            </span>
                            <span className="combo-price">
                            £ 
                              {calculatePriceWithTax(
                                option.price,
                                option.item_tax
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        {validationErrors.length > 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))}
          </div>
        )}
        <div className="attr-popup-footer">
          <div>
            <button type="button" className="attr-back-btn" onClick={onClose}>
              Back
            </button>
          </div>
          <div className="attr-total">
            <span className="attr-price">
            ITEM TOTAL <span>£ {parseFloat(calculateTotalPrice()).toFixed(2)}</span>
            </span>
            <button className="attr-addToCart-btn" onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ComboItemsPopup;
