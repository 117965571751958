import React, { useEffect, useState } from "react";
import LinkedIn from "../assets/images/linkedin.png";
import Facebook from "../assets/images/facebook.png";
// import Twiter from "../assets/images/twitter.png";
import Insta from "../assets/images/Insta.png";
import YouTube from "../assets/images/youtube.png";
import MasterCardLogo from "../assets/images/visaMasterCard.png";
import VisaCardLogo from "../assets/images/visaCardtrans.png";
import PciLogo from "../assets/images/pciLogo.svg";
import { getApitoken } from "../module/landingScreen";

const Footer = () => {
  const [apiToken, setApiToken] = useState(false);

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  return (
    <footer className="text-light">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-3 col-sm-12 col-lg-3">
            <ul className="list-unstyled">
              <li className="mb-3 footer-logo">
                <a href="/">
                  <img src={`${apiToken?.web_footer_logo}`} alt=""></img>
                </a>
              </li>
              <div className="d-flex footer-cards-logo">
                <li>
                  <img src={PciLogo} alt="" />
                </li>
                <li>
                  <img src={MasterCardLogo} alt="" />
                </li>
                {/* <li>
                  <img src={VisaCardLogo} alt="" />
                </li> */}
              </div>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-4">
            <h4>Contact</h4>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a
                  href="tel:0330505883"
                  className="text-decoration-none text-white"
                >
                  {apiToken.mobile_number}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="mailto:suppot@kleocloud.com"
                  className="text-decoration-none text-white"
                >
                  {apiToken.email}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3">
            <h4>Legal</h4>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a
                  href="https://www.kleocloud.com/privacy"
                  className="text-decoration-none text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="https://www.kleocloud.com/terms-and-condition"
                  className="text-decoration-none text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-2 social-media-handles">
            <ul className="list-unstyled d-flex justify-content-between">
              <li>
                <a
                  href={apiToken.instagram || "#"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={Insta} alt="Insta"></img>
                </a>
              </li>
              <li>
                <a
                  href={apiToken.facebook || "#"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={Facebook} alt="Facebook"></img>
                </a>
              </li>
              {/* <li>
                <a href="#" rel="noreferrer" target="_blank">
                  <img src={Twiter} alt="Twiter Icon"></img>
                </a>
              </li> */}
              <li>
                <a
                  href={apiToken.youtube || "#"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={YouTube} alt="YouTube"></img>
                </a>
              </li>
              <li>
                <a
                  href={apiToken.linkedin || "#"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={LinkedIn} alt="LinkedIn"></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="d-flex justify-content-center poweredBy">
          © Powered by Kleo Cloud. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
