import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NoImgFood from "../assets/images/nofood-img-dummy.png";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import {
  fetchItemAttributes,
  fetchItemComboItems,
} from "../module/landingScreen";

export default function ItemCard({
  item,
  addToCart,
  cartItems,
  increaseQuantity,
  decreaseQuantity,
  theme,
  onShowPopup,
  onShowAttributesPopup,
  onShowComboItemsPopup,
}) {
  const { id } = useParams();
  const [expanded, setExpanded] = useState(false);

  const calculatePriceWithTax = (basePrice, taxInfo) => {
    let totalPrice = parseFloat(basePrice) || 0;

    taxInfo.forEach((tax) => {
      const taxPercentage = parseFloat(tax.custom_tax_percentage) || 0;
      const taxAmount = totalPrice * (taxPercentage / 100);
      totalPrice += taxAmount;
    });

    return totalPrice.toFixed(2); // Return the total price rounded to 2 decimal places
  };
  const totalPriceWithTax = calculatePriceWithTax(item.product_price, item.tax);

  useEffect(() => {
    const fetchAndStoreAttributes = async () => {
      if (item && item.attributes && item.attributes.length > 0) {
        try {
          const fetchedAttributes = await fetchItemAttributes(item.id);
          localStorage.removeItem(`attributes_${item.id}`); // Remove existing key
          localStorage.setItem(
            `attributes_${item.id}`,
            JSON.stringify(fetchedAttributes)
          );
        } catch (error) {
          console.error("Error fetching attributes:", error);
        }
      }
    };

    const fetchAndStoreComboItems = async () => {
      if (item && item.combo_items && item.combo_items.length > 0) {
        try {
          const fetchedComboItems = await fetchItemComboItems(item.id);
          localStorage.removeItem(`combo_items_${item.id}`); // Remove existing key
          localStorage.setItem(
            `combo_items_${item.id}`,
            JSON.stringify(fetchedComboItems)
          );
        } catch (error) {
          console.error("Error fetching combo items:", error);
        }
      }
    };

    if (item && id) {
      fetchAndStoreAttributes();
      fetchAndStoreComboItems();
    }
  }, [item, id]);

  const handleAddToCart = (event) => {
    event.stopPropagation();

    if (item.attributes && item.attributes.length > 0) {
      const selectedAttributes = localStorage.getItem(
        `selectedAttributes_${item.id}`
      );
      onShowAttributesPopup(
        item,
        selectedAttributes ? JSON.parse(selectedAttributes) : null
      );
    } else if (item.combo_items && item.combo_items.length > 0) {
      const selectedComboItems = localStorage.getItem(
        `selectedComboItems_${item.id}_${cartItems.length}`
      );
      onShowComboItemsPopup(
        item,
        selectedComboItems ? JSON.parse(selectedComboItems) : null
      );
    } else {
      addToCart(item);
    }
  };

  const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
  const itemImage = item.image || NoImgFood;

  const removePTagsAndSeparateWithCommas = (htmlString) => {
    if (!htmlString) {
      return ""; // Handle case where htmlString is empty or undefined
    }

    const div = document.createElement("div");
    div.innerHTML = htmlString;

    const paragraphs = div.getElementsByTagName("p");
    if (paragraphs.length === 0) {
      // If there are no <p> tags, return the trimmed text content of the div
      return div.textContent.trim();
    }

    const textContents = [];
    for (let i = 0; i < paragraphs.length; i++) {
      textContents.push(paragraphs[i].textContent);
    }

    return textContents.join(", ");
  };

  const descriptionWithCommas = removePTagsAndSeparateWithCommas(
    item.description
  );

  const toggleExpanded = () => setExpanded(!expanded);

  const truncateDescription = (description) => {
    if (!description) return "";
    const words = description.split(" ");
    if (words.length <= 20) return description;
    return words.slice(0, 20).join(" ") + "...";
  };

  return (
    <div className="item-card w-100 justify-content-center mb-3">
      <ToastContainer />
      <div className="d-flex align-items-stretch card-body listing-card">
        <div className="item-card-image">
          <img src={itemImage} alt="Item" />
        </div>
        <div className="item-card-main">
          <div className="desc-left">
            <div className="card-text">
              <div>
                <h4 className="card-title">{item.name}</h4>
                <div
                  className={`card-text-content ${
                    expanded ? "expanded" : "collapsed"
                  }`}
                >
                  {descriptionWithCommas}
                </div>{" "}
                {descriptionWithCommas.split(" ").length > 25 && (
                  <span onClick={toggleExpanded} className="show-more">
                    {expanded ? "Show less" : "Show more"}
                  </span>
                )}
              </div>
            </div>
            <div className="item-card-price">
              <p>
                £{totalPriceWithTax}
                {/* {item.product_price != null
                  ? item.product_price.toFixed(2)
                  : "0.00"} */}
              </p>
            </div>
          </div>
          <div className="desc-bottom">
            <div className="allergens-icons">
              {item.allergens &&
                item.allergens[0].map((allergen, index) => (
                  <img
                    key={index}
                    src={allergen.icon}
                    alt={allergen.allergens}
                    title={allergen.allergens}
                  />
                ))}
            </div>
            <div className="add-btns">
              {cartItem ? (
                <>
                  {(item.attributes && item.attributes.length > 0) ||
                  (item.combo_items && item.combo_items.length > 0) ? (
                    <Button
                      onClick={(event) =>
                        handleAddToCart(
                          event,
                          item.attributes && item.attributes.length > 0
                            ? "attributes"
                            : "comboItems"
                        )
                      }
                      className="edit-customization"
                    >
                      Add New Customization
                    </Button>
                  ) : null}
                  <div className="quantity-controls">
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        decreaseQuantity(
                          cartItems.findIndex((ci) => ci.id === item.id)
                        );
                      }}
                    >
                      -
                    </button>
                    <span>{cartItem.quantity}</span>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        increaseQuantity(
                          cartItems.findIndex((ci) => ci.id === item.id)
                        );
                      }}
                    >
                      +
                    </button>
                  </div>
                </>
              ) : (
                <Button onClick={handleAddToCart}>+ Add</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
